/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React from "react";

const Instrucciones = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <img
          style={{
            width: "100%",
          }}
          src="https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FZOBELE%202025%20DOC-01.jpg?alt=media&token=fc489a8c-7b3c-43b9-9834-3f179ed17e5e"
        />
      </div>
    </>
  );
};

export default Instrucciones;
