import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import SignIn from "../views/signIn";
import Factura from "../views/factura.js";
import Instrucciones from "../views/instrucciones";
import Recepcion from "../views/publico/recepcion/recepcionPacientes";
import { Navigation } from "../components/navigation";
import { useFormulario } from "../hooks/useFormulario";
import Questionario from "../views/publico/questionario/questionario";
import UbicacionLHospitalito from "../views/publico/ubicacionlhospitalito";
import Agenda from "../views/publico/agenda";
import EncuestaZobele from "../views/publico/encuestaZobele";
import SolicitudServicio from "../views/publico/solicitudServicio";
import ValoracionPublico from "../views/publico/valoracion";

const PublicRoutes = () => {
  const { MuiTheme } = useFormulario();
  return (
    <Router>
      <ThemeProvider theme={MuiTheme}>
        <Navigation />
        <Switch>
          <Route exact path="/acceso" component={SignIn} />
          <Route exact path="/factura" component={Factura} />
          <Route exact path="/instrucciones" component={Instrucciones} />
          <Route exact path="/recepcion" component={Recepcion} />
          <Route exact path="/valoracion" component={ValoracionPublico} />
          <Route exact path="/encuesta" component={Questionario} />
          <Route
            exact
            path="/ubicacionlhospitalito"
            component={UbicacionLHospitalito}
          />
          <Route exact path="/encuestas/zobele" component={EncuestaZobele} />
          <Route exact path="/agenda" component={Agenda} />
          <Route
            exact
            path="/solicitudServicio"
            component={SolicitudServicio}
          />
          <Route path="*" component={() => <Redirect to="/acceso" />} />
        </Switch>
      </ThemeProvider>
    </Router>
  );
};

export default PublicRoutes;
